import React from 'react'
import { Wrapper, H, Button } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { WILLS_SIGN_UP_PATH } from 'config'
import WrapperWithEdge from 'components/WrapperWithEdge'
import LinkButton from 'components/LinkButton'
import PreventOrphan from 'components/PreventOrphan'

const GetStarted = ({
  partnerCampaignReferral,
  telephoneWillsEnabled,
  title = 'Ready to finally tick your will off your to-do list?',
}: {
  partnerCampaignReferral: boolean
  telephoneWillsEnabled: boolean
  title?: string
}): React.ReactElement => (
  <WrapperWithEdge direction="up" edgeBackgroundColor={COLOR.WHITE}>
    <Wrapper container containerPaddingTop="L">
      <Wrapper centered maxWidth={550} margin={[0, 'auto']}>
        <H size="L" decorative>
          <PreventOrphan>{title}</PreventOrphan>
        </H>
      </Wrapper>

      <Wrapper margin={['L', 'auto', 0]} centered>
        <LinkButton
          productLink
          buttonTag={Button.White}
          to={WILLS_SIGN_UP_PATH}
          preselectedService="online"
          partnerCampaignReferral={partnerCampaignReferral}
          telephoneWillsEnabled={telephoneWillsEnabled}
          data-track-event="click"
          data-track-element="cta"
          data-track-slice="todo-list-footer"
        >
          Let’s get started
        </LinkButton>
      </Wrapper>
    </Wrapper>
  </WrapperWithEdge>
)

export default GetStarted
