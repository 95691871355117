import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import styled from 'styled-components'
import { Wrapper, Grid, H, Image } from '@farewill/ui'
import {
  GTR,
  CONTENT_OUTER_WIDTH,
  CONTAINER_PADDING_HORIZONTAL_AT_BP,
  CONTAINER_PADDING_VERTICAL_AT_BP,
} from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { paragraphL } from '@farewill/ui/helpers/text'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'

import LinkButton from 'components/LinkButton'
import PreventOrphan from 'components/PreventOrphan'
import TrustpilotSummary from 'components/TrustpilotWidget/TrustpilotSummary'
import { WILLS_SIGN_UP_PATH } from 'config'

type HeroProps = {
  ctaText: string
  subtitle?: string
  telephoneWillsEnabled: boolean
  title: string
}

type FormatPathArgs = { ext: string; path: string; width: number }

const IMAGE_MAX_WIDTH_BREAKPOINT = 1695
const IMAGE_HEIGHT = 650

const StyledRelativeWrapper = styled(Wrapper)`
  position: relative;
`

const StyledGrid = styled(Grid)`
  align-items: center;

  /**
   * This essentially re-implements the container styles, we can't use the prop
   * as we only want the grid to behave as a container from L screens.
   */
  ${screenMin.l`
    max-width: ${CONTENT_OUTER_WIDTH};
    padding: ${GTR[CONTAINER_PADDING_VERTICAL_AT_BP.L]} ${
    GTR[CONTAINER_PADDING_HORIZONTAL_AT_BP.L]
  };
    margin: 0 auto;
  `}

  @media (min-width: ${IMAGE_MAX_WIDTH_BREAKPOINT + parseInt(GTR.XXL, 10)}px) {
    /**
     * This max-width is calculated so the left edge of the grid is aligned to
     * other containers in the page. We extend the max-width so that the text
     * content fills the whitespace between the image which is now adhered to
     * the right of the viewport.
     */
    max-width: calc(
      (100vw - ${CONTENT_OUTER_WIDTH}) / 2 + ${CONTENT_OUTER_WIDTH}
    );
    margin-right: 0;
  }
`

const StyledImageGridItem = styled(Grid.Item)`
  align-self: stretch;

  ${screenMin.l`
    && {
      /**
       * Allow the image to take up the container vertical padding space so that
       * the image has the same height as the whole hero.
       */
      margin: -${GTR[CONTAINER_PADDING_VERTICAL_AT_BP.L]} 0;
    }
  `}

  /**
   * Once we have exceeded the maximum image width, the image should adhere to
   * the right of the screen
   */
  @media (min-width: ${IMAGE_MAX_WIDTH_BREAKPOINT}px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    && {
      margin: 0;
    }
  }
`

const StyledSmallImage = styled(Image)`
  clip-path: circle(170% at 50% -148%);

  ${screenMin.m`
    display: none;
  `}
`

const StyledMediumImage = styled(Image)`
  display: none;
  clip-path: circle(210% at 50% -263%);

  ${screenMin.m`
    display: block;
  `}

  ${screenMin.l`
    display: none;
  `}
`

const LARGE_IMAGE_STYLES = `
  clip-path: circle(farthest-side at right 30%);
  height: 100%;
`

const StyledLargeImage = styled(Image)`
  ${LARGE_IMAGE_STYLES}
  display: none;

  ${screenMin.l`
    display: block;
  `}

  ${screenMin.xl`
    display: none;
  `}
`

const StyledExtraLargeImage = styled(Image)`
  ${LARGE_IMAGE_STYLES}
  display: none;

  ${screenMin.xl`
    display: block;
  `}
`

const StyledContentGridItem = styled(Grid.Item)``

const StyledSubtitle = styled.div`
  p {
    ${paragraphL}
  }
`

const Hero = ({
  title,
  subtitle,
  ctaText,
  telephoneWillsEnabled,
}: HeroProps): React.ReactElement => (
  <StyledRelativeWrapper>
    <StyledGrid gap={0} gapFromL="XL" gapFromXL="XXL">
      <StyledImageGridItem
        span={12}
        spanFromL={6}
        startColumnFromL={7}
        spanFromXL={7}
        startColumnFromXL={6}
      >
        <StyledSmallImage
          formatPath={({ ext, path, width }: FormatPathArgs) =>
            `${CLOUDINARY_ROOT_PATH}/ar_1.8,c_fill,f_auto,q_auto,g_faces,w_${width}/${path}.${ext}`
          }
          path="com-assets/macmillan/hero"
          width={300}
          widthFromS={648}
          stretch
        />

        <StyledMediumImage
          formatPath={({ ext, path, width }: FormatPathArgs) =>
            `${CLOUDINARY_ROOT_PATH}/ar_2.23,c_fill,f_auto,q_auto,g_faces,w_${width}/${path}.${ext}`
          }
          path="com-assets/macmillan/hero"
          width={964}
          stretch
        />

        <StyledLargeImage
          formatPath={({ ext, path }: FormatPathArgs) =>
            `${CLOUDINARY_ROOT_PATH}/ar_1.05,c_fill,f_auto,q_auto,g_faces,h_${IMAGE_HEIGHT}/${path}.${ext}`
          }
          path="com-assets/macmillan/hero"
        />

        <StyledExtraLargeImage
          formatPath={({ ext, path }: FormatPathArgs) =>
            `${CLOUDINARY_ROOT_PATH}/ar_1.4,c_fill,f_auto,q_auto,g_faces,h_${IMAGE_HEIGHT}/${path}.${ext}`
          }
          path="com-assets/macmillan/hero"
        />
      </StyledImageGridItem>

      <StyledContentGridItem
        span={12}
        spanFromL={6}
        spanFromXL={5}
        container
        containerPaddingBottom="XXL"
        paddingFromL={0}
      >
        <H size="L" decorative>
          <PreventOrphan>{title}</PreventOrphan>
        </H>

        {subtitle && (
          <StyledSubtitle>
            <ReactMarkdown
              rehypePlugins={
                [rehypeRaw, rehypeSanitize] as ReactMarkdown.PluggableList
              }
            >
              {subtitle}
            </ReactMarkdown>
          </StyledSubtitle>
        )}

        <Wrapper margin={['M', 0, 'S']} marginFromM={['L', 0, 'M']}>
          <LinkButton
            partnerCampaignReferral
            productLink
            to={WILLS_SIGN_UP_PATH}
            preselectedService="online"
            telephoneWillsEnabled={telephoneWillsEnabled}
          >
            {ctaText}
          </LinkButton>
        </Wrapper>

        <TrustpilotSummary />
      </StyledContentGridItem>
    </StyledGrid>
  </StyledRelativeWrapper>
)

export default Hero
