import React from 'react'
import styled from 'styled-components'
import { Wrapper, H } from '@farewill/ui'
import {
  GTR,
  CONTAINER_PADDING_HORIZONTAL_AT_BP,
  CONTENT_OUTER_WIDTH,
} from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import PreventOrphan from 'components/PreventOrphan'

import GiftStory from './GiftStory'
import { GIFT_STORIES } from './constants'

const StyledFlexWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  max-width: ${CONTENT_OUTER_WIDTH};
  margin: 0 auto;
  gap: ${GTR.L};

  ${screenMin.l`
    gap: ${GTR.XL};
  `}
`

const GiftStories = (): React.ReactElement => (
  <Wrapper>
    <H
      size="L"
      decorative
      container
      containerVerticalPadding={0}
      margin={[0, 0, 'L']}
      centeredFromL
      marginFromL={[0, 'auto', 'XXL']}
      maxWidthInColumns={9}
    >
      <PreventOrphan>
        Over a third of Macmillan’s vital services are funded by gifts in wills
      </PreventOrphan>
    </H>

    <StyledFlexWrapper paddingFromL={[0, CONTAINER_PADDING_HORIZONTAL_AT_BP.L]}>
      {GIFT_STORIES.map(({ title, description, source, image }, index) => (
        <GiftStory
          key={source}
          title={title}
          description={description}
          source={source}
          image={image}
          imageOnLeft={index % 2 !== 0}
        />
      ))}
    </StyledFlexWrapper>
  </Wrapper>
)

export default GiftStories
