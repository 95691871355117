import React from 'react'
import { graphql } from 'gatsby'
import { BREAKPOINT, COLOR } from '@farewill/ui/tokens'
import { Divider, Wrapper } from '@farewill/ui'

import PATHS from 'paths'
import { TRUSTPILOT_RATING_OUT_OF_5, TRUSTPILOT_REVIEWS_TOTAL } from 'config'
import { PRODUCTS } from 'lib/products/constants'
import { TRACKING_TYPES } from 'lib/tracking/constants'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'
import DefaultLayout from 'layouts/DefaultLayout'
import BreakpointSwitch from 'components/BreakpointSwitch'
import GetStarted from 'views/wills/components/Overview/GetStarted'

import Hero from './slices/Hero'
import Trustpilot from './slices/Trustpilot'
import Pledges from './slices/Pledges'
import FAQ from './slices/FAQ'
import WillOptions from './slices/WillOptions'
import GiftStories from './slices/GiftStories'
import GiftSlider from './slices/GiftSlider'

interface MacmillanGreyLabelLandingPageProps {
  data: {
    page: {
      utmCampaign: string
      utmMedium: string
      partner: {
        partnerId: string
        utmSource: string
        utmChannel: string
        telephoneWillsEnabled: boolean
      }
      heroTitle: string | null
      heroSubtitle: { heroSubtitle: string } | null
      heroCtaText: string | null
      telephoneWillsEnabled: boolean | null
    }
  }
}

const MacmillanGreyLabelLandingPage = ({
  data: {
    page: {
      utmCampaign,
      utmMedium,
      partner,
      heroTitle,
      heroSubtitle,
      heroCtaText,
      telephoneWillsEnabled,
    },
  },
}: MacmillanGreyLabelLandingPageProps): React.ReactElement => {
  const areTelephoneWillsEnabled =
    typeof telephoneWillsEnabled === 'boolean'
      ? telephoneWillsEnabled
      : partner.telephoneWillsEnabled

  return (
    <DefaultLayout
      branding={partner.utmSource}
      title="Online Will Writing Service | Legal Will In 15 Minutes"
      description={`Our solicitor-approved guide helps you write a will online from the comfort of your own home. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
        TRUSTPILOT_REVIEWS_TOTAL
      )} customers on Trustpilot. National Will Writing Firm of the Year.`}
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
      product={PRODUCTS.WILLS}
      canonicalPath={PATHS.WILLS.OVERVIEW}
      headerColor={COLOR.WHITE}
      headerType="macmillan"
      footerType="compact"
      utmParameters={{
        partnerId: partner?.partnerId || TRACKING_TYPES.NOT_SET,
        utmCampaign: utmCampaign || TRACKING_TYPES.NOT_SET,
        utmMedium: utmMedium || TRACKING_TYPES.NOT_SET,
        utmSource: partner?.utmSource || TRACKING_TYPES.NOT_SET,
        utmChannel: partner?.utmChannel || TRACKING_TYPES.NOT_SET,
      }}
    >
      <Wrapper background={COLOR.WHITE}>
        <Hero
          title={heroTitle || 'Write your will for free today'}
          subtitle={heroSubtitle?.heroSubtitle}
          ctaText={heroCtaText || 'Let’s get started'}
          telephoneWillsEnabled={areTelephoneWillsEnabled}
        />

        <WillOptions telephoneWillsEnabled={areTelephoneWillsEnabled} />

        <Wrapper padding={['XL', 0, 'XXL']} paddingFromL={[96, 0, 'XXL']}>
          <GiftStories />
        </Wrapper>

        <GiftSlider />

        <Wrapper container noTrim>
          <Trustpilot />
          <BreakpointSwitch
            breakAt={BREAKPOINT.M}
            aboveComponent={<Divider />}
          />
          <FAQ />
          <BreakpointSwitch
            breakAt={BREAKPOINT.M}
            aboveComponent={<Divider />}
          />
          <Pledges />
        </Wrapper>

        <GetStarted
          partnerCampaignReferral
          telephoneWillsEnabled={areTelephoneWillsEnabled}
          title="Ready to write your will?"
        />
      </Wrapper>
    </DefaultLayout>
  )
}

export const query = graphql`
  query MacmillanGreyLabelPartnerLandingPage($slug: String!) {
    page: contentfulPartnerCampaignPage(slug: { eq: $slug }) {
      utmCampaign
      utmMedium
      heroTitle
      heroSubtitle {
        heroSubtitle
      }
      heroCtaText
      partner {
        partnerId
        telephoneWillsEnabled
        utmSource
        utmChannel
      }
      telephoneWillsEnabled
    }
  }
`

export default MacmillanGreyLabelLandingPage
