import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { H, Wrapper } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { FONT } from '@farewill/ui/tokens'

import TrustpilotReviewsSection from 'components/TrustpilotWidget/TrustpilotReviews'

const StyledH = styled(H)`
  ${screenMax.m`
    font-size: ${FONT.SIZE.XL};
  `}
`

const Trustpilot = (): ReactElement => (
  <Wrapper margin={[0, 0, 'L']} marginFromL={[0, 0, 'XXL']}>
    <StyledH tag="h2" size="M" decorative centeredFromM>
      Farewill is trusted by Macmillan supporters
    </StyledH>
    <TrustpilotReviewsSection tag="macmillan" />
  </Wrapper>
)

export default Trustpilot
