import React, { ReactElement } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Image, Wrapper } from '@farewill/ui'
import { BREAKPOINT } from '@farewill/ui/tokens'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'

import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'
import BreakpointSwitch from 'components/BreakpointSwitch'

const FAQ = (): ReactElement => {
  const { faqSection } = useStaticQuery<{
    faqSection: GatsbyTypes.ContentfulFaqSection
  }>(graphql`
    query {
      faqSection: contentfulFaqSection(
        entryTitle: { eq: "Macmillan campaign FAQ" }
      ) {
        ...FaqSectionFields
      }
    }
  `)
  return (
    <Wrapper margin={0} marginFromL={['XXL', 0, 'XXL']}>
      <FrequentlyAskedQuestions
        title="Frequently asked questions"
        customImageComponent={
          <BreakpointSwitch
            breakAt={BREAKPOINT.M}
            aboveComponent={
              <Image
                path="com-assets/macmillan-faq-photo"
                formatPath={({ path, ext, width }) =>
                  `${CLOUDINARY_ROOT_PATH}/c_fill,f_auto,q_auto,h_${width},w_${width}/r_max/${path}.${ext}`
                }
                ext="jpg"
                width={350}
              />
            }
          />
        }
        questions={faqSection.questions}
        customAmount={4}
      />
    </Wrapper>
  )
}

export default FAQ
