export type GiftStory = {
  title: string
  description: string
  source: string
  image: string
}

export const GIFT_STORIES: GiftStory[] = [
  {
    title: 'Help nurses like Sandra to support people like Jerry',
    description:
      'Our specialist Macmillan nurse Sandra was always on hand and we still attend a support group she started 13 years ago. I was in and out of hospital with infections from the osteoradionecrosis and could always ring or email our nurse to get seen in clinic or get advice.',
    source: 'Jerry, diagnosed with mouth cancer in 2014',
    image: 'com-assets/macmillan/jerry',
  },
  {
    title: 'Help people like Naveed get financial support',
    description:
      'I had to apply for Universal Credit but they didn’t believe that I was waiting for treatment. I appealed through Macmillan, via the Macmillan Support Line. They gave me their time and straightaway said they’d do it for me.',
    source: 'Naveed, diagnosed with a rare heart cancer in 2016',
    image: 'com-assets/macmillan/naveed',
  },
]

export const BREAKPOINT = ''
