import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Grid, H, Image, P } from '@farewill/ui'
import { FONT } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'

const StyledP = styled(P)`
  ${screenMax.m`
    font-size: ${FONT.SIZE.M};
  `}
`

const Pledges = (): ReactElement => (
  <Grid margin={['L', 0]} marginFromL={['XXL', 0, 'XL']}>
    <Grid.Item spanFromL={8}>
      <H tag="h2" size="L" maxWidth={660}>
        Over £9 million pledged to Macmillan through Farewill in 2023
      </H>
      <StyledP size="L" maxWidthInColumns={6}>
        Whether your gift is large or small, you’ll be helping to fund vital
        services for people who need them.
      </StyledP>
    </Grid.Item>
    <Grid.Item spanFromL={4} paddingFromL={['S', 0, 0]}>
      <Image
        path="fl_sanitize/cobranding-partner-logos/macmillan"
        formatPath={({ path, ext }) => `${CLOUDINARY_ROOT_PATH}/${path}.${ext}`}
        ext="svg"
        width={250}
        widthFromL={400}
        stretch
      />
    </Grid.Item>
  </Grid>
)

export default Pledges
