import { COLOR, FONT } from '@farewill/ui/tokens'
import { MarkObj } from 'rc-slider/lib/Marks'

type SliderConfig = MarkObj & { value: number; text: string; image: string }

const SliderMarkStyles: MarkObj['style'] = {
  top: '-45px',
  fontSize: '16px',
  color: COLOR.BLACK,
  fontWeight: FONT.WEIGHT.MEDIUM,
}

export const sliderConfig: SliderConfig[] = [
  {
    value: 0,
    style: SliderMarkStyles,
    label: '5%',
    text: '5% of an average estate could help run a large Macmillan Welfare Benefits Advice service for over a month, providing people living with cancer with  impartial advice on claiming financial support.',
    image: 'com-assets/macmillan/macmillan_gift_5',
  },
  {
    value: 25,
    style: SliderMarkStyles,
    label: '10%',
    text: '10% of an average estate could support the Cancer Information and Support team to run the Macmillan Support Line phone service for over a week, helping people needing practical or financial support.',
    image: 'com-assets/macmillan/macmillan_gift_10',
  },
  {
    value: 50,
    style: SliderMarkStyles,
    label: '25%',
    text: '25% of an average estate could fund a Macmillan nurse for over 2 years helping people living with cancer and their families receive essential medical, practical and emotional support.',
    image: 'com-assets/macmillan/macmillan_gift_25',
  },
  {
    value: 75,
    style: SliderMarkStyles,
    label: '50%',
    text: '50% of an average estate could pay for a fully kitted Macmillan Information Space in a non-clinical area such as a hospital waiting area or library.',
    image: 'com-assets/macmillan/macmillan_gift_50',
  },
  {
    value: 100,
    style: SliderMarkStyles,
    label: '100%',
    text: '100% of an average estate could help run our Online Community forum for over 2 years. In that time, 1.3 million people affected by cancer in the UK could use the forum to help and support each other.',
    image: 'com-assets/macmillan/macmillan_gift_100',
  },
]

export const values = sliderConfig.map((config) => config.value)
export const marks = sliderConfig.reduce((acc, curr) => {
  acc[curr.value] = {
    style: curr.style,
    label: curr.label,
  }
  return acc
}, {} as Record<string | number, MarkObj | React.ReactNode>)
