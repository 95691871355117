import React from 'react'
import styled from 'styled-components'
import { Grid, Wrapper, H } from '@farewill/ui'
import { BREAKPOINT, COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'

import WrapperWithEdge from 'components/WrapperWithEdge'
import PreventOrphan from 'components/PreventOrphan'
import BreakpointSwitch from 'components/BreakpointSwitch'
import LinkButton from 'components/LinkButton'
import Texture from 'components/Texture'
import {
  ONLINE_WILL_PRICE_IN_POUNDS,
  PRINTED_WILL_PRICE_IN_POUNDS,
  ONLINE_WILL_SUBSCRIPTION_PRICE_IN_POUNDS,
  WILLS_SIGN_UP_PATH,
} from 'config'
import { PRESELECTED_SERVICE_VALUES } from 'lib/url/wills/constants'
import { WillOption } from 'views/wills/components/WillOptions/types'

import WillOptionCard from './Card'

const CARD_MIN_WIDTH = 360
/**
 * The minimum screen width at which we can fit 3 cards with all spacing
 */
const GRID_BREAKPOINT =
  CARD_MIN_WIDTH * 3 + parseInt(GTR.M, 10) * (3 - 1) + parseInt(GTR.L, 10) * 2

const StyledRoot = styled(WrapperWithEdge)`
  position: relative;
  overflow: hidden;
`

const StyledFingerprintTexture = styled(Texture)`
  transform: rotate(145deg);
  top: -40px;
  right: -25%;

  ${screenMin.l`
    right: -15%;
  `}

  ${screenMin.xl`
    right: -5%;
  `}
`

const StyledBlobTexture = styled(Texture)`
  top: 225px;
  left: 0;

  @media (min-width: ${GRID_BREAKPOINT}px) {
    top: 195px;
  }
`

const StyledDotsTexture = styled(Texture)`
  transform: scaleX(-1);
  top: 245px;
  left: -2%;

  @media (min-width: ${GRID_BREAKPOINT}px) {
    top: 215px;
  }
`

const StyledContentWrapper = styled(Wrapper)`
  position: relative;
  z-index: 2;
`
const StyledGrid = styled(Grid)`
  ${screenMax.m`
    max-width: 368px;
  `}
`

export type WillOptionWithFeatures = WillOption & {
  features: {
    id: string
    text: string
  }[]
}

export const ESSENTIAL_WILL: WillOptionWithFeatures = {
  title: 'The Essential Will',
  description:
    'Farewill has been crowned National Will Writer of the Year four times at the British Wills and Probate Awards',
  singlePrice: ONLINE_WILL_PRICE_IN_POUNDS,
  optionalExtras: [
    {
      name: 'Professionally printed will',
      cost: `£${PRINTED_WILL_PRICE_IN_POUNDS}`,
    },
    {
      name: 'Unlimited updates',
      cost: `£${ONLINE_WILL_SUBSCRIPTION_PRICE_IN_POUNDS} a year`,
    },
  ],
  features: [
    {
      id: 'from-home',
      text: 'Write your will online, from the comfort of home',
    },
    {
      id: 'check-will',
      text: 'Every will is checked to make sure your wishes are clear',
    },
    {
      id: 'print-sign',
      text: 'Print and sign your will to make it legally valid',
    },
  ],
  cta: ({ telephoneWillsEnabled }) => (
    <LinkButton
      partnerCampaignReferral
      productLink
      preselectedService={PRESELECTED_SERVICE_VALUES.ONLINE}
      telephoneWillsEnabled={telephoneWillsEnabled}
      to={WILLS_SIGN_UP_PATH}
    >
      Get started today
    </LinkButton>
  ),
}

type WillOptionsProps = {
  telephoneWillsEnabled: boolean
}

const WillOptions = ({
  telephoneWillsEnabled,
}: WillOptionsProps): React.ReactElement => (
  <StyledRoot edgeBackgroundColor={COLOR.WHITE} direction="down">
    <BreakpointSwitch
      breakAt={BREAKPOINT.S}
      belowComponent={null}
      aboveComponent={
        <>
          <StyledFingerprintTexture
            path="textures/fingerprint-yellow-03_rolgha"
            width={574}
            stretch
          />
          <StyledBlobTexture
            path="textures/blob-yellow-4"
            width={250}
            stretch
          />
          <StyledDotsTexture
            path="textures/yellow-detailed-dots"
            width={270}
            stretch
          />
        </>
      }
    />

    <StyledContentWrapper container noTrim>
      <H size="L" decorative centeredFromM marginFromM={[0, 'auto', 'XL']}>
        <PreventOrphan>An award-winning online service</PreventOrphan>
      </H>
      <StyledGrid maxWidthInColumns={10} margin={[0, 'auto']}>
        <WillOptionCard
          data={ESSENTIAL_WILL}
          telephoneWillsEnabled={telephoneWillsEnabled}
        />
      </StyledGrid>
    </StyledContentWrapper>
  </StyledRoot>
)

export default WillOptions
