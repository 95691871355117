import React from 'react'
import styled from 'styled-components'
import { Grid, H, P, Image } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { headingM } from '@farewill/ui/helpers/text'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'

import PreventOrphan from 'components/PreventOrphan'

import { GiftStory as GiftStoryData } from '../constants'

type GiftStoryProps = GiftStoryData & {
  imageOnLeft?: boolean
}

type FormatPathArgs = { ext: string; path: string; width: number }

const StyledGrid = styled(Grid)`
  align-items: center;
`

const StyledImageGridItem = styled(Grid.Item)<{ $imageOnLeft: boolean }>`
  ${({ $imageOnLeft }) =>
    $imageOnLeft &&
    `
    justify-self: flex-end;
  `}
`

const StyledSmallImage = styled(Image)`
  clip-path: circle(170% at 50% -148%);

  ${screenMin.m`
    display: none;
  `}
`

const StyledMediumImage = styled(Image)`
  display: none;
  clip-path: circle(170% at 50% -224%);

  ${screenMin.m`
    display: block;
  `}

  ${screenMin.l`
    display: none;
  `}
`

const StyledLargeImage = styled(Image)`
  display: none;
  justify-self: flex-end;

  ${screenMin.l`
    display: block;
  `}
`

const StyledTitle = styled(H)`
  ${screenMin.m`
    ${headingM}
  `}
`

const GiftStory = ({
  title,
  description,
  source,
  image,
  imageOnLeft = false,
}: GiftStoryProps): React.ReactElement => (
  <StyledGrid gap={0}>
    {/**
     * Only 1 image should be visible at any screen width. The unstyled Wrapper
     * groups all the images into a single column so that only 1 gap is applied.
     */}
    <StyledImageGridItem
      span={12}
      spanFromL={5}
      startColumnFromL={imageOnLeft ? 1 : 8}
      spanFromXL={4}
      startColumnFromXL={imageOnLeft ? 2 : 8}
      $imageOnLeft={imageOnLeft}
    >
      <StyledSmallImage
        formatPath={({ ext, path, width }: FormatPathArgs) =>
          `${CLOUDINARY_ROOT_PATH}/ar_1.8,c_fill,f_auto,q_auto,g_faces,w_${width}/${path}.${ext}`
        }
        path={image}
        width={300}
        widthFromS={648}
        stretch
      />

      <StyledMediumImage
        formatPath={({ ext, path, width }: FormatPathArgs) =>
          `${CLOUDINARY_ROOT_PATH}/ar_2.5,c_fill,f_auto,q_auto,g_faces,w_${width}/${path}.${ext}`
        }
        path={image}
        width={964}
        stretch
      />

      <StyledLargeImage
        formatPath={({ ext, path, width }: FormatPathArgs) =>
          `${CLOUDINARY_ROOT_PATH}/ar_1:1,c_fill,f_auto,q_auto,g_custom:faces,w_${width},r_max/${path}.${ext}`
        }
        path={image}
        width={400}
        stretch
      />
    </StyledImageGridItem>

    {/**
     * For large screens, a container is applied in the parent GiftStories
     */}
    <Grid.Item
      span={12}
      spanFromL={6}
      startColumnFromL={imageOnLeft ? 7 : 1}
      spanFromXL={5}
      startColumnFromXL={imageOnLeft ? 7 : 2}
      container
      containerPaddingBottom={0}
      paddingFromL={0}
    >
      <StyledTitle size="S">
        <PreventOrphan>{title}</PreventOrphan>
      </StyledTitle>
      <P>“{description}”</P>
      <strong>{source}</strong>
    </Grid.Item>
  </StyledGrid>
)

export default GiftStory
