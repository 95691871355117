import React from 'react'
import styled from 'styled-components'
import {
  Wrapper,
  H,
  P,
  Grid,
  BulletedList,
  TickIcon,
  Divider,
} from '@farewill/ui'
import { BREAKPOINT, GTR, COLOR, FONT } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'

import BreakpointSwitch from 'components/BreakpointSwitch'

import { WillOptionWithFeatures } from '.'

export type WillOptionCardProps = {
  data: WillOptionWithFeatures
  className?: string
  telephoneWillsEnabled: boolean
}

const CARD_BORDER_RADIUS = '16px'
const PRICE_DISCOUNT_RED = '#ea0038'

const StyledTopWrapper = styled(Wrapper)`
  border-radius: ${CARD_BORDER_RADIUS};
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const StyledHeader = styled(Wrapper)`
  border-radius: ${CARD_BORDER_RADIUS} ${CARD_BORDER_RADIUS} 0 0;

  ${screenMin.l`
    border-bottom: 1px solid ${COLOR.GREY.LIGHT};
  `}
`

const StyledTitle = styled(H)`
  font-weight: ${FONT.WEIGHT.MEDIUM};

  ${screenMax.m`
    font-size: ${FONT.SIZE.L};
  `}
`

const StyledFreePrice = styled.s`
  text-decoration: line-through 1.5px;
`

const StyledFree = styled.span`
  color: ${PRICE_DISCOUNT_RED};
`

const StyledOptionalExtraHeading = styled(H)`
  font-size: ${FONT.SIZE.S};
`

const StyledOptionalExtra = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`

const StyledOptionsWrapper = styled(Wrapper)`
  ${screenMin.m`
    max-width: 280px;
  `}
`

const StyledLinkButtonWrapper = styled(Wrapper)`
  ${screenMax.s`
    a {
      max-width: none;
    }
  `}
`

const StyledBulletedListItem = styled(BulletedList.Item)`
  * {
    gap: ${GTR.S};
  }
`

const WillOptionCard = ({
  data,
  className,
  telephoneWillsEnabled,
}: WillOptionCardProps): React.ReactElement => {
  const {
    title,
    description,
    singlePrice,
    optionalExtras,
    features,
    cta: Cta,
  } = data
  return (
    <>
      <Grid.Item className={className}>
        <StyledTopWrapper background={COLOR.WHITE}>
          <StyledHeader padding="M" centeredFromL>
            <StyledTitle size="S" tag="h3">
              {title}
            </StyledTitle>
          </StyledHeader>
          <Wrapper padding={['0', 'M', 'M']} paddingFromL={['L', 'XL', 'XL']}>
            <Grid columns={10} gapFromL="0" padding={[0, 0, 'M']}>
              <Grid.Item spanFromL={4}>
                <H size="L" tag="h4" margin={['auto', 0, 'XS']}>
                  <StyledFreePrice>{`£${singlePrice}`}</StyledFreePrice>{' '}
                  <StyledFree aria-label="Free with your discount code">
                    Free
                  </StyledFree>
                </H>
                <P color={PRICE_DISCOUNT_RED} aria-hidden>
                  With your Macmillan discount code
                </P>
                {optionalExtras && (
                  <>
                    <StyledOptionalExtraHeading
                      tag="h5"
                      color={COLOR.GREY.DARK}
                      margin={['M', 0, 0]}
                    >
                      Optional extras
                    </StyledOptionalExtraHeading>
                    <StyledOptionsWrapper
                      margin={0}
                      padding={['XS', 0, 0]}
                      tag="dl"
                    >
                      {optionalExtras.map(({ name, cost }) => (
                        <StyledOptionalExtra key={name} margin={[0, 0, 'XS']}>
                          <P tag="dt" size="S" margin={0}>
                            {name}
                          </P>
                          <P tag="dd" size="S" margin={0}>
                            {cost}
                          </P>
                        </StyledOptionalExtra>
                      ))}
                    </StyledOptionsWrapper>
                  </>
                )}
              </Grid.Item>
              <BreakpointSwitch
                breakAt={BREAKPOINT.M}
                belowComponent={
                  <Grid.Item>
                    <Divider />
                  </Grid.Item>
                }
              />
              <Grid.Item spanFromL={5} startColumnFromL={6}>
                <P
                  color={COLOR.GREY.DARK}
                  margin={[0, 0, 'M']}
                  maxWidth={460}
                  size="S"
                  strong
                >
                  {description}
                </P>
                <BulletedList
                  icon={
                    <TickIcon
                      background={COLOR.ACCENT.PRIMARY}
                      color={COLOR.BLACK}
                    />
                  }
                  gap={GTR.S}
                >
                  {features.map((feature) => (
                    <StyledBulletedListItem key={feature.id}>
                      <P size="S">{feature.text}</P>
                    </StyledBulletedListItem>
                  ))}
                </BulletedList>
              </Grid.Item>
            </Grid>
            <StyledLinkButtonWrapper margin={['M', 0]} centered>
              {Cta({ isFree: true, telephoneWillsEnabled })}
            </StyledLinkButtonWrapper>
          </Wrapper>
        </StyledTopWrapper>
      </Grid.Item>
    </>
  )
}

export default WillOptionCard
